// assets/app.js

// Importando CSS do Bootstrap e Bootstrap Icons
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

// Importando Font Awesome
import '@fortawesome/fontawesome-free/js/all.js';

// Importando jQuery
import $ from 'jquery';

// Importando JS do Bootstrap e Popper.js
import 'bootstrap';
import 'bootstrap/dist/js/bootstrap.bundle.min';
